/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // read more toggle for services section on front page
        $('.service__read-more-btn a').click(function(event) {
          event.preventDefault();
          $('.service__content-wrapper').matchHeight({ remove: true });
          $extraContent = $(this).parent().siblings('.service__content-wrapper').children('.service__read-more-content');
          if (!$extraContent.is(':visible')) {
            $(this).text('Read Less');
          } else {
            $(this).text('Read More');
          }
          $extraContent.slideToggle();


        });

        //open gallery in swipebox
        $('.gallery__image-zoom').swipebox();

        // page scroll to id
        $("a[href*='#']").mPageScroll2id();
        
        // init logos slider
        $('.logo-slider').slick({
          dots: false,
          arrows: false,
          infinite: true,
          autoplay: true,
          speed: 4000,
          autoplaySpeed: 0,
          cssEase: 'linear',
          slidesToShow: 6,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
                dots: false
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });

        $('.video-wrapper video').click(function(event) {
          $('.video-wrapper .video-overlay').toggle('fast');
        });

        // responsive video size
        function resizeVideo() {
          var width   = $('.full-width-video-bg').outerWidth();
          var height  = $('.full-width-video-bg').outerHeight();

          var s_w = 1920;
          var s_h = 1080;


          if (s_w > s_h) {

            var new_w = width;
            var ratio = new_w/s_w;
            var new_h = s_h * ratio;

          }
          else {
            var new_h = height;
            var ratio = new_h/s_h;
            var new_w = s_w * ratio;
          }

          if (new_h < height) {
            var ratio = height/new_h;
            new_w = new_w * ratio;
            new_h = new_h * ratio;
          }

          $('.full-width-video-bg video').css({"width" : new_w+ "px","height" : new_h+ "px"});
          $('.full-width-video-bg video').attr("width", new_w+"px");
          $('.full-width-video-bg video').attr("height", new_h+"px");

          var left = width/2 - new_w/2;
          var top  = height/2 - new_h/2;
          $('.full-width-video-bg video').css({left : left + "px", top: + top +"px", "position":"absolute"});
        }


        resizeVideo();
        $(window).resize(function(event) {
          resizeVideo();
        });
        


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Projects Page
    'projects': {
      init: function() {
        // JavaScript to be fired on the home page

        // init Isotope
        var $grid = $('.gallery-grid').isotope({
          itemSelector: '.gallery__image-wrapper',
          masonry: {
            columnWidth: '.gallery__image-wrapper'
          },
        });

        // bind filter button click
        $('.gallery-filter .btn').on( 'click', function() {
          var filterValue = $(this).children('input').attr('data-filter');
          $grid.isotope({ filter: filterValue });
        });

        


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'contact': {
      init: function() {
        
          // JavaScript to be fired on the about us page
          function initMap() {
          // map styles
          
          // setup map
          map = new google.maps.Map(document.getElementById('map'), {
            center: {lat: -37.7369243, lng: 175.2281206},
            disableDefaultUI: true,
            // styles: styles,
            scrollwheel: false,
            zoom: 15,
          });
          // custom map marker
          marker = new google.maps.Marker({
              map:map,
              animation: google.maps.Animation.DROP,
              position: new google.maps.LatLng(-37.7369243, 175.2281206),
              icon: '/wp-content/themes/rope_access/dist/images/map-marker.png',
            });
          // setting up info window
          var contentString = '<div id="content"><p><strong>Rope Access</strong><br><a href="https://www.google.co.nz/maps/place/Rope+Access+New+Zealand+Limited/@-37.7369243,175.2259319,17z/data=!4m13!1m7!3m6!1s0x6d6d231ef836d505:0x6effc1b2630710b7!2s11+Kaimiro+St,+Pukete,+Hamilton+3200!3b1!8m2!3d-37.7369243!4d175.2281206!3m4!1s0x6d6d231e5870fb4f:0x2c2fb11eb88c649f!8m2!3d-37.7368527!4d175.2282727" target="_blank"> 11 Kaimiro St, Pukete, Hamilton 3200</a></p></div>';
          var infowindow = new google.maps.InfoWindow({
            content: contentString
          });
          // show marker on click
          marker.addListener('click', function() {
            infowindow.open(map, marker);
          });
          // reset center on screen resize
          google.maps.event.addDomListener(window, "resize", function() {
              var center = map.getCenter();
              google.maps.event.trigger(map, "resize");
              map.setCenter(center);
          });
        }

        initMap();
      
      }
    }

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
